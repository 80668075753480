import _newlines from "./newlines";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/**
 * References:
 *
 *   - http://en.wikipedia.org/wiki/ANSI_escape_code
 *   - http://www.termsys.demon.co.uk/vtansi.htm
 *
 */

/**
 * Module dependencies.
 */
var emitNewlineEvents = _newlines,
    prefix = "\x1B[" // For all escape codes
,
    suffix = "m"; // Only for color codes

/**
 * The ANSI escape sequences.
 */

var codes = {
  up: "A",
  down: "B",
  forward: "C",
  back: "D",
  nextLine: "E",
  previousLine: "F",
  horizontalAbsolute: "G",
  eraseData: "J",
  eraseLine: "K",
  scrollUp: "S",
  scrollDown: "T",
  savePosition: "s",
  restorePosition: "u",
  queryPosition: "6n",
  hide: "?25l",
  show: "?25h"
};
/**
 * Rendering ANSI codes.
 */

var styles = {
  bold: 1,
  italic: 3,
  underline: 4,
  inverse: 7
};
/**
 * The negating ANSI code for the rendering modes.
 */

var reset = {
  bold: 22,
  italic: 23,
  underline: 24,
  inverse: 27
};
/**
 * The standard, styleable ANSI colors.
 */

var colors = {
  white: 37,
  black: 30,
  blue: 34,
  cyan: 36,
  green: 32,
  magenta: 35,
  red: 31,
  yellow: 33,
  grey: 90,
  brightBlack: 90,
  brightRed: 91,
  brightGreen: 92,
  brightYellow: 93,
  brightBlue: 94,
  brightMagenta: 95,
  brightCyan: 96,
  brightWhite: 97
};
/**
 * Creates a Cursor instance based off the given `writable stream` instance.
 */

function ansi(stream, options) {
  if (stream._ansicursor) {
    return stream._ansicursor;
  } else {
    return stream._ansicursor = new Cursor(stream, options);
  }
}

exports = exports = ansi;
/**
 * The `Cursor` class.
 */

function Cursor(stream, options) {
  if (!((this || _global) instanceof Cursor)) {
    return new Cursor(stream, options);
  }

  if (typeof stream != "object" || typeof stream.write != "function") {
    throw new Error("a valid Stream instance must be passed in");
  } // the stream to use


  (this || _global).stream = stream; // when 'enabled' is false then all the functions are no-ops except for write()

  (this || _global).enabled = options && options.enabled;

  if (typeof (this || _global).enabled === "undefined") {
    (this || _global).enabled = stream.isTTY;
  }

  (this || _global).enabled = !!(this || _global).enabled; // then `buffering` is true, then `write()` calls are buffered in
  // memory until `flush()` is invoked

  (this || _global).buffering = !!(options && options.buffering);
  (this || _global)._buffer = []; // controls the foreground and background colors

  (this || _global).fg = (this || _global).foreground = new Colorer(this || _global, 0);
  (this || _global).bg = (this || _global).background = new Colorer(this || _global, 10); // defaults

  (this || _global).Bold = false;
  (this || _global).Italic = false;
  (this || _global).Underline = false;
  (this || _global).Inverse = false; // keep track of the number of "newlines" that get encountered

  (this || _global).newlines = 0;
  emitNewlineEvents(stream);
  stream.on("newline", function () {
    (this || _global).newlines++;
  }.bind(this || _global));
}

exports.Cursor = Cursor;
/**
 * Helper function that calls `write()` on the underlying Stream.
 * Returns `this` instead of the write() return value to keep
 * the chaining going.
 */

Cursor.prototype.write = function (data) {
  if ((this || _global).buffering) {
    (this || _global)._buffer.push(arguments);
  } else {
    (this || _global).stream.write.apply((this || _global).stream, arguments);
  }

  return this || _global;
};
/**
 * Buffer `write()` calls into memory.
 *
 * @api public
 */


Cursor.prototype.buffer = function () {
  (this || _global).buffering = true;
  return this || _global;
};
/**
 * Write out the in-memory buffer.
 *
 * @api public
 */


Cursor.prototype.flush = function () {
  (this || _global).buffering = false;

  var str = (this || _global)._buffer.map(function (args) {
    if (args.length != 1) throw new Error("unexpected args length! " + args.length);
    return args[0];
  }).join("");

  (this || _global)._buffer.splice(0); // empty


  this.write(str);
  return this || _global;
};
/**
 * The `Colorer` class manages both the background and foreground colors.
 */


function Colorer(cursor, base) {
  (this || _global).current = null;
  (this || _global).cursor = cursor;
  (this || _global).base = base;
}

exports.Colorer = Colorer;
/**
 * Write an ANSI color code, ensuring that the same code doesn't get rewritten.
 */

Colorer.prototype._setColorCode = function setColorCode(code) {
  var c = String(code);
  if ((this || _global).current === c) return;
  (this || _global).cursor.enabled && (this || _global).cursor.write(prefix + c + suffix);
  (this || _global).current = c;
  return this || _global;
};
/**
 * Set up the positional ANSI codes.
 */


Object.keys(codes).forEach(function (name) {
  var code = String(codes[name]);

  Cursor.prototype[name] = function () {
    var c = code;

    if (arguments.length > 0) {
      c = toArray(arguments).map(Math.round).join(";") + code;
    }

    (this || _global).enabled && this.write(prefix + c);
    return this || _global;
  };
});
/**
 * Set up the functions for the rendering ANSI codes.
 */

Object.keys(styles).forEach(function (style) {
  var name = style[0].toUpperCase() + style.substring(1),
      c = styles[style],
      r = reset[style];

  Cursor.prototype[style] = function () {
    if ((this || _global)[name]) return this || _global;
    (this || _global).enabled && this.write(prefix + c + suffix);
    (this || _global)[name] = true;
    return this || _global;
  };

  Cursor.prototype["reset" + name] = function () {
    if (!(this || _global)[name]) return this || _global;
    (this || _global).enabled && this.write(prefix + r + suffix);
    (this || _global)[name] = false;
    return this || _global;
  };
});
/**
 * Setup the functions for the standard colors.
 */

Object.keys(colors).forEach(function (color) {
  var code = colors[color];

  Colorer.prototype[color] = function () {
    this._setColorCode((this || _global).base + code);

    return (this || _global).cursor;
  };

  Cursor.prototype[color] = function () {
    return (this || _global).foreground[color]();
  };
});
/**
 * Makes a beep sound!
 */

Cursor.prototype.beep = function () {
  (this || _global).enabled && this.write("\x07");
  return this || _global;
};
/**
 * Moves cursor to specific position
 */


Cursor.prototype.goto = function (x, y) {
  x = x | 0;
  y = y | 0;
  (this || _global).enabled && this.write(prefix + y + ";" + x + "H");
  return this || _global;
};
/**
 * Resets the color.
 */


Colorer.prototype.reset = function () {
  this._setColorCode((this || _global).base + 39);

  return (this || _global).cursor;
};
/**
 * Resets all ANSI formatting on the stream.
 */


Cursor.prototype.reset = function () {
  (this || _global).enabled && this.write(prefix + "0" + suffix);
  (this || _global).Bold = false;
  (this || _global).Italic = false;
  (this || _global).Underline = false;
  (this || _global).Inverse = false;
  (this || _global).foreground.current = null;
  (this || _global).background.current = null;
  return this || _global;
};
/**
 * Sets the foreground color with the given RGB values.
 * The closest match out of the 216 colors is picked.
 */


Colorer.prototype.rgb = function (r, g, b) {
  var base = (this || _global).base + 38,
      code = rgb(r, g, b);

  this._setColorCode(base + ";5;" + code);

  return (this || _global).cursor;
};
/**
 * Same as `cursor.fg.rgb(r, g, b)`.
 */


Cursor.prototype.rgb = function (r, g, b) {
  return (this || _global).foreground.rgb(r, g, b);
};
/**
 * Accepts CSS color codes for use with ANSI escape codes.
 * For example: `#FF000` would be bright red.
 */


Colorer.prototype.hex = function (color) {
  return (this || _global).rgb.apply(this || _global, hex(color));
};
/**
 * Same as `cursor.fg.hex(color)`.
 */


Cursor.prototype.hex = function (color) {
  return (this || _global).foreground.hex(color);
}; // UTIL FUNCTIONS //

/**
 * Translates a 255 RGB value to a 0-5 ANSI RGV value,
 * then returns the single ANSI color code to use.
 */


function rgb(r, g, b) {
  var red = r / 255 * 5,
      green = g / 255 * 5,
      blue = b / 255 * 5;
  return rgb5(red, green, blue);
}
/**
 * Turns rgb 0-5 values into a single ANSI color code to use.
 */


function rgb5(r, g, b) {
  var red = Math.round(r),
      green = Math.round(g),
      blue = Math.round(b);
  return 16 + red * 36 + green * 6 + blue;
}
/**
 * Accepts a hex CSS color code string (# is optional) and
 * translates it into an Array of 3 RGB 0-255 values, which
 * can then be used with rgb().
 */


function hex(color) {
  var c = color[0] === "#" ? color.substring(1) : color,
      r = c.substring(0, 2),
      g = c.substring(2, 4),
      b = c.substring(4, 6);
  return [parseInt(r, 16), parseInt(g, 16), parseInt(b, 16)];
}
/**
 * Turns an array-like object into a real array.
 */


function toArray(a) {
  var i = 0,
      l = a.length,
      rtn = [];

  for (; i < l; i++) {
    rtn.push(a[i]);
  }

  return rtn;
}

export default exports;
const _Cursor = exports.Cursor,
      _Colorer = exports.Colorer;
export { _Cursor as Cursor, _Colorer as Colorer };