import _assert from "assert";
var exports = {};

/**
 * Accepts any node Stream instance and hijacks its "write()" function,
 * so that it can count any newlines that get written to the output.
 *
 * When a '\n' byte is encountered, then a "newline" event will be emitted
 * on the stream, with no arguments. It is up to the listeners to determine
 * any necessary deltas required for their use-case.
 *
 * Ex:
 *
 *   var cursor = ansi(process.stdout)
 *     , ln = 0
 *   process.stdout.on('newline', function () {
 *    ln++
 *   })
 */

/**
 * Module dependencies.
 */
var assert = _assert;
var NEWLINE = "\n".charCodeAt(0);

function emitNewlineEvents(stream) {
  if (stream._emittingNewlines) {
    // already emitting newline events
    return;
  }

  var write = stream.write;

  stream.write = function (data) {
    // first write the data
    var rtn = write.apply(stream, arguments);

    if (stream.listeners("newline").length > 0) {
      var len = data.length,
          i = 0; // now try to calculate any deltas

      if (typeof data == "string") {
        for (; i < len; i++) {
          processByte(stream, data.charCodeAt(i));
        }
      } else {
        // buffer
        for (; i < len; i++) {
          processByte(stream, data[i]);
        }
      }
    }

    return rtn;
  };

  stream._emittingNewlines = true;
}

exports = emitNewlineEvents;
/**
 * Processes an individual byte being written to a stream
 */

function processByte(stream, b) {
  assert.equal(typeof b, "number");

  if (b === NEWLINE) {
    stream.emit("newline");
  }
}

export default exports;